<script setup lang="ts">
import { DialogRoot, type DialogRootEmits, useForwardPropsEmits } from 'radix-vue'
import { computed, withDefaults, ref, watch } from 'vue'
import * as DialogPresenters from './presets'
import { type DialogPresenter, type DialogPresenterConfig, provideDialogContext, provideDialogPresenter, relayDialogPreseterConfig } from './types'

defineOptions({
  inheritAttrs: false
})

interface Props {
  preset?: keyof typeof DialogPresenters | DialogPresenter
  defaultOpen?: boolean
}

const props = withDefaults(defineProps<Props & Partial<DialogPresenterConfig>>(), {
  preset: "standard",
  defaultOpen: false
})

const emits = defineEmits<DialogRootEmits>()

const callbacks = [] as Array<() => void>
const modelOpen = defineModel<any>('open')

const isOpen = computed(() => {
  if (modelOpen.value === undefined) {
    return props.defaultOpen
  }

  return !!modelOpen.value
})

const delegatedProps = computed(() => {
  const { preset: _1, defaultOpen: _2, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
provideDialogContext({
  close() {
    onOpenChanged(false)
  },
  onOpen(callback) {
    callbacks.push(callback)
    if (isOpen.value) {
      callback()
    }
  },
})

function onOpenChanged(value: boolean) {
  console.log('onOpenChanged', value)
  modelOpen.value = value

  if (value) {
    for (const callback of callbacks) {
      callback()
    }
  }
}

const preseterConfig = relayDialogPreseterConfig(props)
const presenter = provideDialogPresenter(computed(() => {
  if (typeof props.preset != 'string') {
    return props.preset
  }

  return DialogPresenters[props.preset]
}))
</script>

<template>
  <DialogRoot v-bind="forwarded" :open="isOpen" @update:open="onOpenChanged">
    <slot />
  </DialogRoot>
</template>
