import { bva } from '../utils'
import type { CardPresenter } from './types'

export const standard = {
  root: bva(`
    rounded-md flex flex-col border bg-card text-card-foreground shadow-sm
  `, {}),
  header: bva(`
    flex flex-col gap-y-1.5 p-6 !py-4 border-b
  `, {}),
  content: bva(`
    p-6 !py-5 grow
  `, {}),
  footer: bva(`
    flex items-center p-6 !py-5
  `, {}),
  title: bva(`
    font-semibold leading-none tracking-tight
  `, {}),
  description: bva(`
    text-sm text-muted
  `, {}),
} satisfies CardPresenter

export const table = {
  root: bva(`
    rounded-md border bg-card text-card-foreground shadow-sm
  `, {}),
  header: bva(`
    flex flex-col gap-y-1.5 p-6 !py-4 border-b
  `, {}),
  content: bva(`
    py-2 !px-4
  `, {}),
  footer: bva(`
    flex items-center p-6 !py-5
  `, {}),
  title: bva(`
    font-semibold leading-none tracking-tight
  `, {}),
  description: bva(`
    text-sm text-muted
  `, {}),
} satisfies CardPresenter
