<template>
  <input type="radio" v-model="model_value" :value="value" :class="cn(presenter.root(preseterConfig), props.class)">
</template>

<script setup lang="ts">
import * as RadioPresenters from './presets'
import { provideRadioPresenter, relayRadioPreseterConfig, type RadioPresenter, type RadioPresenterConfig } from './types'
import { type HTMLAttributes, computed, withDefaults } from 'vue'
import { cn } from '../utils'

interface Props {
  class?: HTMLAttributes['class']
  preset: keyof typeof RadioPresenters | RadioPresenter
}

const model_value = defineModel<any[]>({ required: true })
const props = withDefaults(defineProps<Props & Partial<RadioPresenterConfig>>(), {
  preset: "standard"
})

const preseterConfig = relayRadioPreseterConfig(props)
const presenter = provideRadioPresenter(computed(() => {
  if (typeof props.preset != 'string') {
    return props.preset
  }

  return RadioPresenters[props.preset]
}))
</script>
