<script setup lang="ts">
import { type HTMLAttributes, computed, withDefaults } from 'vue'
import type { CheckboxRootEmits, CheckboxRootProps } from 'radix-vue'
import { CheckboxIndicator, CheckboxRoot, useForwardPropsEmits } from 'radix-vue'
import { CheckIcon } from '@radix-icons/vue'
import { cn } from '../utils'
import { provideCheckboxPresenter, relayCheckboxPreseterConfig, type CheckboxPresenter, type CheckboxPresenterConfig } from './types'
import * as CheckboxPresenters from './presets'

interface Props {
  class?: HTMLAttributes['class']
  preset?: keyof typeof CheckboxPresenters | CheckboxPresenter
}

const props = withDefaults(defineProps<Props & Partial<CheckboxPresenterConfig>>(), {
  preset: 'standard',
})
const emits = defineEmits<CheckboxRootEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)

const preseterConfig = relayCheckboxPreseterConfig(props)
const presenter = provideCheckboxPresenter(computed(() => {
  if (typeof props.preset != 'string') {
    return props.preset
  }

  return CheckboxPresenters[props.preset]
}))
</script>

<template>
  <CheckboxRoot v-bind="forwarded" :class="cn(presenter.root(preseterConfig), props.class)">
    <CheckboxIndicator class="flex h-full w-full items-center justify-center text-current">
      <slot>
        <CheckIcon class="h-4 w-4" />
      </slot>
    </CheckboxIndicator>
  </CheckboxRoot>
</template>
