
import { type Ref } from "vue"
import { createProvideInject, createRelayPreseterConfig } from '../utils'

export interface RadioPresenterConfig {
}

export type RadioPresenter = {
  root: (config: Partial<RadioPresenterConfig>) => string
}

export const {
  relayInjectPreseterConfig: relayRadioPreseterConfig
} = createRelayPreseterConfig<RadioPresenterConfig>('radio-presenter-config', {
  size: 'default',
})

export const {
  useInject: useRadioPresenter,
  useProvide: provideRadioPresenter
} = createProvideInject<Ref<RadioPresenter>>('radio-presenter')
