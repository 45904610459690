import { bva } from '../utils'
import type { CheckboxPresenter } from './types'

export const standard = {
  root: bva(`
    peer h-4 w-4 shrink-0 rounded-sm border border-primary shadow
    focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring
    disabled:cursor-not-allowed disabled:opacity-50
    data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground
  `, {}),
} satisfies CheckboxPresenter
