// framework
import './vendor/element-ext'
import './vendor/stylesheets/all.scss'

// shadcn
import './assets/style.css'

// widgets
import './vendor/fontawesome'
// import './vendor/unocss'

import { createApp } from 'vue'
import AppTemplate from './AppTemplate.vue'

const app = createApp(AppTemplate)

import * as initializers from './initializers'
for (const key in initializers) {
  initializers[key](app)
}

app.mount('#app')

