import { ErrorAccessDenied, ErrorUnauthorized } from '$vue/lib/requests'
import { UnprocessableEntityError } from "$commons/ui"
import { ErrorsObject } from "$vue/models"
import { plainToInstance } from "class-transformer"
import { AxiosError, type AxiosRequestConfig, type AxiosInstance, type AxiosResponse, type Method } from "axios"
import FormData from 'form-data'
import _ from 'lodash'
import qs from 'qs'
import URI from 'urijs'
import URITemplate from 'urijs/src/URITemplate'
import * as rxjs from "rxjs"

export default abstract class BaseRequest<T> {
  method!: Method
  endpoint!: string

  interpolations = {} as Record<string, any>
  headers = {}
  query = {} as Record<string, any>
  params: any = {}
  $axios: AxiosInstance = null!
  $ctx: any = null!
  subject: rxjs.Subject<T> | null = null

  setup(ctx: { $axios: any }, callback: ((instance: this) => void) | null = null): this {
    this.$axios = ctx.$axios
    this.$ctx = ctx

    if (callback) {
      callback(this)
    }

    return this
  }

  buildUrl() {
    const url = new URITemplate(this.endpoint).expand(this.interpolations)
    const uri = new URI(url)
    const query_string = this.paramsSerializer(this.query)
    return uri.query(query_string).toString()
  }

  async perform(params: any = {}): Promise<T> {
    const subject = this.doPerformSubject(params)
    return await rxjs.firstValueFrom(subject)
  }

  doPerformSubject(params: any = {}): rxjs.Subject<T> {
    if (this.subject) {
      console.log("cached subject")
      return this.subject
    }

    this.subject = new rxjs.ReplaySubject()
    this.doPerform(params).then(it => {
      this.subject!.next(it)
      this.subject!.complete()
    }).catch(e => {
      this.subject!.error(e)
    })

    return this.subject
  }

  async processError(e: any): Promise<T> {
    if (e instanceof AxiosError && e.response?.status === 403) {
      throw new ErrorAccessDenied()
    } else if (e instanceof AxiosError && e.response?.status === 401) {
      throw new ErrorUnauthorized()
    } else if (e instanceof AxiosError && e.response?.status === 422) {
      const errors = this.responseToObject(e.response, ErrorsObject)
      throw new UnprocessableEntityError(errors)
    } else {
      throw e
    }
  }

  responseToObject<K>(resp: AxiosResponse, klass: new () => K): K {
    return plainToInstance(klass, resp.data)
  }

  async doPerform(params: any = {}) {
    const url = this.buildUrl()
    const $keyv = this.$ctx.$keyv

    const config: AxiosRequestConfig = {
      url,
      method: this.method,
      headers: this.headers,
    }
    if (params) {
      const form_data = params instanceof FormData ? params : this.buildFormData(params)
      config.data = form_data
    }

    const key = config.url
    const cached = await $keyv.get(key)
    if (cached && this.method == 'GET') {
      console.log("request cached response", cached)
      return this.processResponse(cached)
    }

    try {
      const resp = await this.$axios.request(config)
      if (this.method == 'GET') {
        await $keyv.set(key, resp)
      } else {
        await $keyv.clear()
      }
      const obj = this.processResponse(resp)
      return obj
    } catch (e: any) {
      return await this.processError(e)
    }
  }

  paramsSerializer(params: any) {
    return qs.stringify(params, { arrayFormat: 'brackets' })
  }

  buildFormData(params: any) {
    const form_data = new FormData()
    for (const name in params) {
      const value = params[name]
      this.fillFormData(form_data, name, value)
    }
    return form_data
  }

  fillFormData(formData: FormData, name: string, value: any) {
    if (_.isArray(value)) {
      for (const [ key, val ] of value.entries()) {
        this.fillFormData(formData, `${name}[]`, val)
      }
    } else if (value instanceof Date) {
      this.fillFormData(formData, name, value.toISOString())
    } else if (_.isPlainObject(value)) {
      for (const attr in value) {
        const val = value[attr]
        this.fillFormData(formData, `${name}[${attr}]`, val)
      }
    } else {
      _.isNull(value) ? formData.append(name, "") : formData.append(name, value)
    }
  }

  abstract processResponse(response: AxiosResponse): T
}
